<template>
  <div class="box">
    <div class="name">
      {{ info.name }}
      <img
        :src="'https://img.weiqilile.com/bank/' + info.institutionCode + '.png'"
        class="logo"
        v-if="info.institutionCode"
      />
    </div>
    <div class="top">
      <div class="price-title">最高额度(万)</div>
      <div class="price">￥{{ info.maxQuota }}</div>
      <div class="top-hint">
        <img src="@/img/product/info-icon1.png" />
        年化低至:{{ info.rate }}%
      </div>
      <div class="top-hint">
        <img src="@/img/product/info-icon2.png" />
        还款方式:{{ info.fangshi }}
      </div>
      <div class="top-hint">
        <img src="@/img/product/info-icon3.png" />
        办理方式:{{
          info.method == 0
            ? "线上+线下办理"
            : info.method == 1
            ? "线下办理"
            : "线上办理"
        }}
      </div>
    </div>
    <div class="information-box">
      <div class="info-title">产品条件</div>
      <div class="information">{{ info.productSupremacy }}</div>
    </div>
    <div class="hint">
      <div class="hint-title">提示：产品办理有风险</div>
      <div class="hint-nr">
        产品办理不是百分百通过，办理次数过多可能会对征信
        造成影响，建议先进行精准测额，提高办理成功率
      </div>
      <div class="hint-button">GO</div>
    </div>
    <div class="information-box">
      <div class="info-title">申请所需资料</div>
      <div class="information">{{ info.applyMaterial }}</div>
    </div>
    <div class="information-box">
      <div class="info-title">办理流程</div>
      <div v-html="info.handProcedur" class="productInfo-info"></div>
    </div>

    <div class="button-box">
      <div
        class="bottom-button"
        style="color: #113fc4; background: #edf1ff"
        @click="shoucang"
      >
        {{ info.collect == 0 ? "收藏此产品" : "取消收藏" }}
      </div>
      <div class="bottom-button" @click="transaction" v-if="info.url">
        立即办理
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import { Toast } from "vant";
import url from "@/service/url-config";
export default {
  data() {
    return {
      repayment: [], //产品还款方式
      zidian: {},
      info: {},
      companyList: [],
      type: "",
      productId: "",
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.productId =
      this.$route.query.type == "tuijian"
        ? this.$route.query.productId
        : this.$route.query.id;
    this.getDict();
    this.$http.get(url.companyList).then((res) => {
      this.companyList = res.data;
    });
  },
  //立即办理
  methods: {
    transaction() {
      if (
        localStorage.getItem("wql_real_token") &&
        localStorage.getItem("wql_real_token") != "undefined"
      ) {
        this.$http.get(url.handleLinks);
        if (this.companyList.length > 0) {
          window.location.href = this.info.url;
        } else {
          Dialog.confirm({
            message: "检测到您目前还没有测额，是否需要立即测额",
            messageAlign: "center",
            confirmButtonText: "立即测额",
            cancelButtonText: "直接办理",
          })
            .then(() => {
              this.$router.push("measurement");
            })
            .catch(() => {
              window.location.href = this.info.url;
            });
        }
      } else {
        Dialog.confirm({
          message: "为了您在微企乐有更好的用户体验，请您登录账户",
          messageAlign: "center",
          confirmButtonText: "立即登录",
          cancelButtonText: "暂不登录",
        }).then(() => {
          this.$router.push("login");
        });
      }
    },
    //收藏产品
    shoucang() {
      this.$http.put(url.collect, { productId: this.productId }).then((res) => {
        if (res.code == 200) {
          if (this.info.collect == 0) {
            Toast.success("收藏成功");
            this.info.collect = 1;
          } else {
            Toast.success("已取消");
            this.info.collect = 0;
          }
        }
      });
    },
    //字典
    async getDict() {
      await this.$http.get(url.dict).then((res) => {
        res.data.forEach((v) => {
          this[v.name] = v.dictDetails;
        });
      });
      this.getInfo();
    },
    getInfo() {
      let zidian = {};
      this.repayment.forEach((v) => {
        zidian[v.value] = v.label;
      });
      if (this.type == "tuijian") {
        this.$http
          .get(
            url.recommendProductInfo + "?recommendId=" + this.$route.query.id
          )
          .then((res) => {
            if (res.data.repayment && res.data.repayment.length > 0) {
              // res.data.rateClerk = res.data.rateClerk.replace(" ", "：");
              res.data.fangshi = "";
              let arr = res.data.repayment.split(",");
              arr.forEach((a) => {
                res.data.fangshi += zidian[a] + " ";
              });
            }
            this.productId = res.data.productId;
            this.info = res.data;
          });
      } else if (this.type == "putong") {
        this.$http.get(url.productInfo + this.$route.query.id).then((res) => {
          res.data.rateClerk = res.data.rateClerk.replace(" ", "：");
          if (res.data.repayment && res.data.repayment.length > 0) {
            res.data.fangshi = "";
            let arr = res.data.repayment.split(",");
            arr.forEach((a) => {
              res.data.fangshi += zidian[a] + " ";
            });
          }
          this.info = res.data;
        });
      }
    },
  },
};
</script>
<style>
.productInfo-info {
  font-size: 14px;
}
.productInfo-info img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>
<style lang="less" scoped>
.box {
  background: #ffffff;
  padding: 20px 30px;
  padding-top: 66px;
  .name {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
    padding-bottom: 15px;
    position: relative;
  }
  .logo {
    width: 60px;
    height: 30px;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .top {
    width: 341px;
    height: 199px;
    background: url("../../img/product/info-bg.png");
    background-size: 100%;
    position: relative;
    left: -10px;
    .price-title {
      text-align: center;
      color: #000000;
      font-weight: bold;
      font-size: 13px;
      padding-top: 28px;
    }
    .price {
      text-align: center;
      color: #4675f8;
      font-size: 40px;
      font-weight: bold;
      margin-top: 10px;
    }
    .top-hint {
      display: flex;
      align-items: center;
      margin-left: 20px;
      margin-bottom: 10px;
      img {
        width: 15px;
        height: 15px;
      }
      font-size: 13px;
      color: #333333;
      font-weight: bold;
    }
  }
  .info-title {
    color: #4675f8;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 18px;
  }
  .hint {
    width: 310px;
    height: 52px;
    padding: 13px 7px;
    background: #fff9ed;
    border-radius: 10px;
    font-size: 10px;
    color: #fa9b31;
    position: relative;
    margin-bottom: 23px;
    margin-top: 10px;
    .hint-title {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .hint-nr {
      width: 246px;
    }
    .hint-button {
      width: 41px;
      height: 17px;
      background: #fa9b31;
      border-radius: 5px;
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      line-height: 17px;
      position: absolute;
      right: 11px;
      top: 38px;
    }
  }
  .commpany-price {
    padding-bottom: 20px;
  }
  .price-num {
    font-size: 20px;
    color: #e92928;
    font-weight: bold;
    margin-right: 15px;
  }
  .price-name {
    font-size: 12px;
    color: #111111;
  }
  .information {
    font-size: 12px;
    color: #333333;
    line-height: 23px;
    white-space: pre-line;
  }
  .information-box {
    margin-top: 26px;
    position: relative;
  }
  .process {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  .share {
    position: fixed;
    right: 25px;
    width: 60px;
    height: 74px;
    text-align: center;
  }
  .share-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* background: #0084FF; */
    margin: 0 auto;
    text-align: center;
    line-height: 50px;
  }
  .share-val {
    font-size: large 12px;
    color: #333333;
    margin-top: 5px;
    color: #1377ff;
  }
  .button-box {
    display: flex;
    justify-content: space-around;
  }
  .bottom-button {
    width: 151px;
    height: 45px;
    border-radius: 22px;
    background: #113fc4;
    border: 1px solid #113fc4;
    color: #ffffff;
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
    line-height: 45px;
  }
}
</style>
